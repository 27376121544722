body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; /* Ensure the video is behind other content */
}

.video-background .video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire background */
}

.content {
  z-index: 20;
  color: white;
  text-align: center;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageContainer {
  position: relative;
  width: 350px;
  height: 350px;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0);
  color: #f1f1f1;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.overlay.hovered {
  height: 100%;
  opacity: 1;
}

.text {
  color: white;
  font-size: 18px;
  text-align: center;
}
